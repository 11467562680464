<template>
    <b-overlay :show="loading" rounded="sm">
        <div v-if="!loading">
            <b-row class="mb-4">
                <b-col lg="6" cols="12" md="12">
                    <b-card no-body class="h-100">
                        <div class="p-4">
                            <!-- Header -->
                            <div class="d-flex align-items-center mb-3">
                                <div>
                                    <h1 class="font-bold mb-0">
                                        AWG {{ formatter.format(serieSummaryLoans.totalAmount) }}
                                    </h1>
                                    <span>Summary of active loan applications</span>
                                </div>
                                <div class="ml-auto">
                                    <i class="mdi mdi-wallet text-primary display-4"></i>
                                </div>
                            </div>

                            <!-- <p class="font-weight-bold">Last Month Summary</p> -->
                        </div>
                        <SummaryTotal :series="serieSummaryLoans.series"></SummaryTotal>
                    </b-card>
                </b-col>
                <b-col lg="3" cols="12" md="3">
                    <b-card no-body class="h-100 d-flex" style="justify-content: space-between">
                        <div class="p-3">
                            <h4 class="card-title">Personal Loans</h4>
                            <h2 class="mt-3 pb-2">AWG {{ formatter.format(personalLoans[0].amount) }}</h2>
                            <h5 class="card-subtitle mb-0 font-weight-normal text-muted">
                                Summary of personal loan applications
                            </h5>
                        </div>
                        <PersonalLoans :series="personalLoans"></PersonalLoans>
                    </b-card>
                </b-col>
                <b-col lg="3" cols="12" md="3">
                    <b-card no-body class="h-100 d-flex" style="justify-content: space-between">
                        <div class="p-3">
                            <h4 class="card-title">Vehicle Loans</h4>
                            <h2 class="mt-3 pb-2">AWG {{ formatter.format(vehicleLoans[0].amount) }}</h2>
                            <h5 class="card-subtitle mb-0 font-weight-normal text-muted">
                                Summary of personal loan applications
                            </h5>
                        </div>
                        <VehicleLoan :series="vehicleLoans"></VehicleLoan>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="12" cols="12" md="12">
                    <b-card class="mb-4">
                        <LoansStatus :status="allData.loansApplicationPerStatus"></LoansStatus>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100">
                        <PersonalLoansQuantity
                            :quantity="allData.quantityOfLoansPerType.personalLoans"
                            :percent="
                                (allData.quantityOfLoansPerType.personalLoans * 100) /
                                allData.quantityOfLoansPerType.totalLoans
                            "
                        ></PersonalLoansQuantity>
                    </b-card>
                </b-col>

                <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100">
                        <VehicleLoanQuantity
                            :quantity="allData.quantityOfLoansPerType.vehicleLoans"
                            :percent="
                                (allData.quantityOfLoansPerType.vehicleLoans * 100) /
                                allData.quantityOfLoansPerType.totalLoans
                            "
                        ></VehicleLoanQuantity>
                    </b-card>
                </b-col>
                <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100">
                        <TotalLoans
                            :quantity="allData.quantityOfLoansPerType.totalLoans"
                            :serie="[
                                allData.quantityOfLoansPerType.personalLoans,
                                allData.quantityOfLoansPerType.vehicleLoans,
                            ]"
                        ></TotalLoans>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100">
                        <CardStatistics
                            title="Active Loans"
                            :quantity="allData.quantityOfLoansPerStatus.activeLoans"
                            :percent="(allData.quantityOfLoansPerStatus.activeLoans * 100) / quantityOfLoansPerStatus"
                        ></CardStatistics>
                    </b-card>
                </b-col>

                <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100">
                        <CardStatistics
                            title="Rejected Loans"
                            :quantity="allData.quantityOfLoansPerStatus.rejectedLoans"
                            :percent="(allData.quantityOfLoansPerStatus.rejectedLoans * 100) / quantityOfLoansPerStatus"
                        ></CardStatistics>
                    </b-card>
                </b-col>
                <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100">
                        <CardStatistics
                            title="Disbursed Loans"
                            :quantity="allData.quantityOfLoansPerStatus.disbursedLoans"
                            :percent="
                                (allData.quantityOfLoansPerStatus.disbursedLoans * 100) / quantityOfLoansPerStatus
                            "
                        ></CardStatistics>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="12" class="d-flex align-items-stretch">
                    <b-card class="mb-4 w-100" no-body>
                        <!-- Header -->
                        <div class="d-md-flex align-items-center card-body">
                            <div>
                                <h4 class="card-title">Top Applications</h4>
                                <h5 class="card-subtitle text-muted font-weight-normal">
                                    Overview of top loan applications
                                </h5>
                            </div>
                            <div class="ml-auto mt-3 mt-md-0">
                                <b-button variant="primary" @click="goToLoanApplications">View All</b-button>
                            </div>
                        </div>
                        <TopLoans :items="allData.topLoanApplications"></TopLoans>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>
<script>
// -----------------------------------------
// Import Components Here
// -----------------------------------------

import SummaryTotal from '../../components/dahsboard/SummaryTotal.vue';
import PersonalLoans from '../../components/dahsboard/PersonalLoans.vue';
import VehicleLoan from '../../components/dahsboard/VehicleLoan.vue';
import LoansStatus from '../../components/dahsboard/LoansStatus.vue';
import PersonalLoansQuantity from '../../components/dahsboard/PersonalLoansQuantity.vue';
import VehicleLoanQuantity from '../../components/dahsboard/VehicleLoanQuantity.vue';
import TotalLoans from '../../components/dahsboard/TotalLoans.vue';
import TopLoans from '../../components/dahsboard/TopLoans.vue';
import CardStatistics from '../../components/dahsboard/CardStatistics.vue';
// -----------------------------------------
// Export Here
// -----------------------------------------
export default {
    name: 'GeneralDashboard',
    data: () => ({
        title: 'GeneralDashboard',
        // Month Table
        month1: 0,
        monthoptions1: [
            { text: 'Monthly', value: 0 },
            { text: 'Daily', value: 1 },
            { text: 'Weekly', value: 2 },
            { text: 'Yearly', value: 3 },
        ],
        visitsoptions1: [
            { text: 'Monthly', value: '0' },
            { text: 'Daily', value: '1' },
            { text: 'Weekly', value: '2' },
            { text: 'Yearly', value: '3' },
        ],
        sellingoptions1: [
            { text: 'Monthly', value: 0 },
            { text: 'Daily', value: 1 },
            { text: 'Weekly', value: 2 },
            { text: 'Yearly', value: 3 },
        ],
        allData: null,
        serieSummaryLoans: [],
        personalLoans: [],
        vehicleLoans: [],
        loading: true,
        formatter: new Intl.NumberFormat('en-US'),
    }),
    components: {
        VehicleLoan,
        PersonalLoans,
        LoansStatus,
        PersonalLoansQuantity,
        VehicleLoanQuantity,
        TotalLoans,
        TopLoans,
        SummaryTotal,
        CardStatistics,
    },
    methods: {
        async getData() {
            this.loading = true;
            this.allData = await this.$store.dispatch('loan/applications/get_dashboardInformation');
            this.serieSummaryLoans = this.allData.summaryActiveLoanApplications;
            this.personalLoans = this.serieSummaryLoans.series.filter((e) => e.name == 'Personal Loan');
            this.vehicleLoans = this.serieSummaryLoans.series.filter((e) => e.name == 'Vehicle Loan');
            this.loading = false;
        },
        goToLoanApplications() {
            this.$router.push('/applications');
        },
    },
    computed: {
        quantityOfLoansPerStatus() {
            return (
                this.allData.quantityOfLoansPerStatus.activeLoans +
                this.allData.quantityOfLoansPerStatus.rejectedLoans +
                this.allData.quantityOfLoansPerStatus.disbursedLoans
            );
        },
    },
    created() {
        this.getData();
    },
};
</script>
